<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="6">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ $t("forget_password.title") }}</span>
        </div>
        <el-alert v-if="showNotRegisteredYet" :closable="false" type="error">
          <div>
            <i class="fas fa-exclamation-circle action-icon-withour-pointer" />
            {{ $t("forget_password.not_registered_yet") }}
            <el-link
              :underline="false"
              class="forget-password"
              type="success"
              @click="toRegisterPage"
              >{{ $t("landingPage.banner.signup") }}</el-link
            >
          </div>
        </el-alert>
        <el-form
          class="forgot-password-form"
          :rules="rules"
          :model="model"
          ref="form"
          :validate-on-rule-change="false"
          @submit.prevent.native="handleForgetPassword()"
        >
          <div class="explain">
            {{ $t("forget_password.explain") }}
          </div>
          <el-form-item prop="email">
            <el-input
              v-model="model.email"
              :placeholder="$t('forget_password.email')"
              prefix-icon="el-icon-user"
            />
          </el-form-item>
          <el-form-item align="right">
            <el-button type="primary" block @click="handleForgetPassword"
              >{{ $t("forget_password.submit") }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "Forget Password - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      showNotRegisteredYet: false,
      model: {
        email: ""
      }
    };
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {},

  created() {
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
  },

  methods: {
    ...mapActions("password", ["sendRestLink"]),

    async handleForgetPassword() {
      try {
        let valid = await this.$refs.form.validate();

        if (!valid) {
          return false;
        }
      } catch (e) {
        return e;
      }
      try {
        await this.sendRestLink(this.model);
      } catch (e) {
        this.showNotRegisteredYet = true;
        return;
      }
      this.$router.push({
        name: "Login",
        query: {
          email: this.model.email,
          fromForgetPassword: true
        }
      });
    },
    toRegisterPage() {
      this.$router.push({
        name: "Register"
      });
    }
  }
};
</script>

<style scoped>
.login-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: #42a16a;
}

.forgot-password-form {
  margin-top: 20px;
}

.clearfix {
  color: white;
}

.explain {
  color: #777777;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
